import { XoneDataCollection } from "../appData/core/XoneDataCollection";
import XmlNode from "../appData/Xml/JSONImpl/XmlNode";
import { loremIpsum } from "lorem-ipsum";

/**
 * generate Unique Id
 * @returns {string}
 */
export const generateUniqueId = () => Math.random().toString(36).substr(2, 9);

export const generateUniqueDeviceId = () => {
	return `${Math.random().toString(36).substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}-${Math.random()
		.toString(36)
		.substr(2, 9)}-${Math.random().toString(36).substr(2, 9)}`;
};

export const generateRandomString = (length = 20) => loremIpsum().substring(0, length);

/**
 * generate a Document from xml string
 * @param {string} xmlString
 * @returns {Document}
 */
export const parseXoneXml = (xmlString) => new DOMParser().parseFromString(xmlString, "text/xml");

const isEmpty = (x) => typeof x == "undefined" || x.length == 0 || x == null;

const capComment = 1,
	capSelector = 2,
	capEnd = 3,
	capAttr = 4;
const commentX = /\/\*[\s\S]*?\*\//g;
const lineAttrX = /([^\:]+):([^\;]*);/;
const altX = /(\/\*[\s\S]*?\*\/)|([^\s\;\{\}][^\;\{\}]*(?=\{))|(\})|([^\;\{\}]+\;(?!\s*\*\/))/gim;

/**
 * Input is css string and current pos, returns JSON object
 * @param {string} cssString
 * @param {object} [args]
 */
export const parseXoneCss = (
	cssString,
	args = {
		comments: false,
		stripComments: true,
	}
) => {
	const node = {};

	let match = null;
	let count = 0;

	if (args.stripComments) {
		args.comments = false;
		cssString = cssString.replace(commentX, "");
	}
	while ((match = altX.exec(cssString))) {
		if (!isEmpty(match[capComment]) && args.comments) {
			// Comment
			node[count++] = match[capComment].trim();
		} else if (!isEmpty(match[capSelector])) {
			// New node, we recurse
			const name = match[capSelector].trim();
			// This will return when we encounter a closing brace
			const newNode = parseXoneCss(cssString, args);

			[name]
				.map((e) => e?.trim())
				.forEach((sel) => {
					if (sel in node) {
						newNode.attributes?.forEach((att) => (node[sel][att] = newNode.attributes[att]));
					} else {
						node[sel] = newNode;
					}
				});
		} else if (!isEmpty(match[capEnd])) {
			// Node has finished
			return node;
		} else if (!isEmpty(match[capAttr])) {
			const line = match[capAttr].trim();
			const attr = lineAttrX.exec(line);
			if (!attr) continue;
			// Attribute
			const name = attr[1].trim();
			const value = attr[2].trim();
			node[name] = value;
		}
	}

	return node;
};

/**
 *
 * @param {XoneDataCollection} dataColl
 * @param {number} [rowsLength]
 */
export const createTestingDataGeneration = () =>
	(window.generateTestingData = (dataColl, rowsLength = 10) => {
		/** @type {{m_xmlNode:XmlNode}} */
		const { m_xmlNode } = dataColl;

		if (m_xmlNode.getAttrValue("objname").toString().toLowerCase() === "usuarios") rowsLength = 1;

		const props = {};
		m_xmlNode.SelectNodes("prop").map((/** @type {XmlNode} */ e) => {
			/** @type {string} */
			const propName = e.getAttrValue("name");
			// if (propName.startsWith("MAP_")) return;
			props[propName] = e.getAttrValue("type");
		});

		const data = [...Array(rowsLength).keys()].map((n) => {
			const row = { ...props };
			Object.entries(props).forEach(([key, value]) => {
				if (key === "LOGIN") value = "admin";
				else if (key === "PWD") value = null;
				else if (key === "IDEMPRESA") value = 1;
				else if (value.toString().startsWith("T")) value = generateRandomString();
				else if (value === "NC") value = Math.round(Math.random());
				else if (value.toString().startsWith("N")) value = Math.floor(Math.random() * 100);
				else if (value.toString().startsWith("D")) value = new Date(Math.floor(Math.random() * 2636111528424));
				else if (value === "IMG") value = `https://picsum.photos/200?id=${generateUniqueId()}`;
				else value = "";

				row[key] = value;
			});
			row.ID = n + 1;
			return row;
		});
		return data;
	});

String.prototype.compress = function (asArray) {
	"use strict";
	// Build the dictionary.
	asArray = asArray === true;
	var i,
		dictionary = {},
		uncompressed = this,
		c,
		wc,
		w = "",
		result = [],
		ASCII = "",
		dictSize = 256;
	for (i = 0; i < 256; i += 1) {
		dictionary[String.fromCharCode(i)] = i;
	}

	for (i = 0; i < uncompressed.length; i += 1) {
		c = uncompressed.charAt(i);
		wc = w + c;
		//Do not use dictionary[wc] because javascript arrays
		//will return values for array['pop'], array['push'] etc
		// if (dictionary[wc]) {
		if (dictionary.hasOwnProperty(wc)) {
			w = wc;
		} else {
			result.push(dictionary[w]);

			ASCII += String.fromCharCode(dictionary[w]);
			// Add wc to the dictionary.

			dictionary[wc] = dictSize++;
			w = String(c);
		}
	}

	// Output the code for w.
	if (w !== "") {
		result.push(dictionary[w]);

		ASCII += String.fromCharCode(dictionary[w]);
	}
	return asArray ? result : ASCII;
};

String.prototype.decompress = function () {
	("use strict");
	// Build the dictionary.
	var i,
		tmp = [],
		dictionary = [],
		compressed = this,
		w,
		result,
		k,
		entry = "",
		dictSize = 256;
	for (i = 0; i < 256; i += 1) {
		dictionary[i] = String.fromCharCode(i);
	}

	if (compressed && typeof compressed === "string") {
		// convert string into Array.
		for (i = 0; i < compressed.length; i += 1) {
			tmp.push(compressed[i].charCodeAt(0));
		}

		compressed = tmp;

		tmp = null;
	}

	w = String.fromCharCode(compressed[0]);
	result = w;
	for (i = 1; i < compressed.length; i += 1) {
		k = compressed[i];

		if (dictionary[k]) {
			entry = dictionary[k];
		} else {
			if (k === dictSize) {
				entry = w + w.charAt(0);
			} else {
				return null;
			}
		}

		result += entry;

		// Add w+entry[0] to the dictionary.
		dictionary[dictSize++] = w + entry.charAt(0);

		w = entry;
	}
	return result;
};

let crypto;

export const encrypt = async (/** @type {string} */ st, /** @type {string} */ type) => {
	if (!crypto) crypto = (await import("crypto-js")).default;
	const fnc = crypto[type.toUpperCase()];
	if (!fnc) return st;
	return fnc(st)?.toString();
};

window.encrypt = encrypt;
